import { feTrack } from '@wff/mithra/analytics/feTrack';
import { wffTrackableEvents } from '@wff/mithra/analytics/wffTrackableEvents';
import { buildGoTo } from '@wff/mithra/config/platformRoutes';
import { PreSignUpFormData, WffCity } from '@wff/types';
import { CitySelector } from '@wff/ui/homepage/GetStarted/CitySelector';
import { LeadUserModal } from '@wff/ui/homepage/LeadUserModal';
import React, { useState } from 'react';
import Modal from 'react-modal';

export const GetStarted = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<PreSignUpFormData>({
    firstName: '',
    lastName: '',
    email: '',
    phonenumber: '',
    relocationCity: WffCity.Berlin,
  });

  const updateFormState = (newData: Partial<PreSignUpFormData>) => {
    setFormData((prevFormData) => {
      return { ...prevFormData, ...newData };
    });
  };

  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);

  const onPickerToggle = (isOpen?: boolean) => {
    if (isOpen !== undefined) {
      setIsPickerOpen(isOpen);
      return;
    }
    setIsPickerOpen((prevState) => !prevState);
  };

  Modal.setAppElement('body');
  return (
    <React.Fragment>
      <div className="mt-7" data-aos="fade-down" data-aos-duration="3000">
        <CitySelector
          isPickerOpen={isPickerOpen}
          onToggle={onPickerToggle}
          selectedCity={formData.relocationCity}
          onCitySelect={(city) => {
            updateFormState({ relocationCity: city });
          }}
        />
      </div>

      <button
        onClick={() => {
          const updatedUrl = buildGoTo(window.location.pathname, {
            search: { signup: 'started' },
            currentUrl: window.location.href,
          });
          window.history.pushState('signupStarted', '', updatedUrl);
          feTrack(wffTrackableEvents.flatSeeker.signupStarted);
          setIsOpen(true);
        }}
        data-aos="fade-down"
        data-aos-duration="3000"
        className="mt-6 sm:w-48 w-32 py-2.5 text-base font-bold rounded-lg bg-wffblue text-white"
      >
        Get Started
      </button>
      <LeadUserModal
        isOpen={isOpen}
        setIsOpen={(isOpen) => setIsOpen(isOpen)}
        formData={formData}
        updateFormState={updateFormState}
        hideRelocationCity={true}
      />
    </React.Fragment>
  );
};
